.footer {
  background: #1c2832;
}

.footer-logo {
  margin: auto;
  display: block;
  border: solid 8px #fff;
  border-radius: 100px;
  background: #fff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.footer-copy {
  text-align: center;
  font-size: 12px;
  color: #dad0a1;
  opacity: 0.4;
  padding: 30px;
  position: relative;
}