.body {
    min-height: 70vh;
}

.button {
    display: inline-block;
    background: #1c2832;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
    padding: 14px 25px;
    -webkit-font-smoothing: antialiased;
    -webkit-animation: offbutton 0.3s ease;
    animation: offbutton 0.3s ease;
    transition: all 0.3s ease;
    border: none 0px;
    -webkit-appearance: none;
    cursor: pointer;
}

.loader {
    margin-top: 10%;
    max-height: 80px;
}